import React from 'react';
import { MatchedRoute as MatchedRouteBase, RouteConfig as RouteConfigBase } from 'react-router-config';
import { Redirect } from 'react-router-dom';
import sites from '../constants/sites';
import Home from '../components/Home';
import Exchange from '../components/Exchange';
import NotFound from '../components/NotFound';
import Info from '../components/Info';
import Story from '../components/Story';
import Partners from '../components/Partners';
import ConnectedNetworks from '../components/ConnectedNetworks';
import Collection from '../components/Collection';
import Service from '../components/Service';
import { MenuScheme } from '../entities/operationResults';
import Documentation from '../components/Documentation';
import News from '../components/News';
import Event from '../components/Event';
import GetStarted from '../components/GetStarted';
import Search from '../components/Search';
import Glossary from "../components/Glossary";
import ServiceSlideover from '../components/organisms/ServiceSlideover';

export const primaryExchange = sites.find(({ primary }) => !!primary);
if (!primaryExchange) {
    throw new Error('Fatal: could not found a exchange marked as primary (check constants/site.ts)');
}

export interface RouteConfig extends RouteConfigBase {
    menuScheme?: MenuScheme;
}

export interface MatchedRoute<T> extends MatchedRouteBase<T> {
    route: RouteConfig;
}

export const exchangeRoute: RouteConfig = {
    path: '/:exchange',
    component: Exchange
};

export const homeRoute: RouteConfig = {
    path: `${exchangeRoute.path}`,
    component: Home,
    exact: true
};

export const glossaryRoute: RouteConfig = {
    path: `${exchangeRoute.path}/glossary`,
    component: Glossary,
    exact: true
};

export const searchRoute: RouteConfig = {
    path: `${exchangeRoute.path}/search`,
    component: Search,
    exact: true,
};
export const eventsRoute: RouteConfig = {
    path: `${exchangeRoute.path}/events`,
    component: Collection,
    exact: true,
};

export const newsRoute: RouteConfig = {
    path: `${exchangeRoute.path}/news`,
    component: Collection,
    exact: true
};

export const newsletterRoute: RouteConfig = {
    path: `${exchangeRoute.path}/newsletter`,
    component: Collection,
    exact: true
};

export const storiesRoute: RouteConfig = {
    path: `${exchangeRoute.path}/stories`,
    component: Collection,
    exact: true
};

export const partnersRoute: RouteConfig = {
    path: `${exchangeRoute.path}/where-to-connect`,
    component: Partners,
    exact: true
};

export const connectedNetworksRoute: RouteConfig = {
    path: `${exchangeRoute.path}/connected-networks`,
    component: ConnectedNetworks,
    exact: true
};

export const storyRoute: RouteConfig = {
    path: `${storiesRoute.path}/:slug`,
    component: Story,
    exact: true
};

export const documentationRoute: RouteConfig = {
    path: `${exchangeRoute.path}/documentation/:slug`,
    component: Documentation,
    exact: true
};

export const eventRoute: RouteConfig = {
    path: `${eventsRoute.path}/:slug`,
    component: Event,
    exact: true
};

export const singleNewsRoute: RouteConfig = {
    path: `${newsRoute.path}/:slug`,
    component: News,
    exact: true
};

export const serviceRoute: RouteConfig = {
    path: `${exchangeRoute.path}/service/:slug`,
    component: Service,
    menuScheme: MenuScheme.dark
};

export const serviceSlideoverRoute: RouteConfig = {
    path: `${exchangeRoute.path}/service/:slug/learn-more`,
    component: ServiceSlideover,
    menuScheme: MenuScheme.dark
};

export const infoRoute: RouteConfig = {
    path: `${exchangeRoute.path}/:slug`,
    component: Info,
    exact: true
};

export const getStartedRoute: RouteConfig = {
    path: `${exchangeRoute.path}/get-started`,
    component: GetStarted,
    exact: true
};

// when adding a route make sure it's in the array before info (info is a fallback)

exchangeRoute.routes = [
    searchRoute,
    eventsRoute,
    eventRoute,
    newsRoute,
    newsletterRoute,
    singleNewsRoute,
    storiesRoute,
    storyRoute,
    homeRoute,
    partnersRoute,
    connectedNetworksRoute,
    documentationRoute,
    getStartedRoute,
    serviceRoute,
    glossaryRoute,
    serviceSlideoverRoute,
    infoRoute,
    {
        path: `${infoRoute.path}/*`,
        component: NotFound
    }
];

const routes: RouteConfig[] = [
    exchangeRoute,
    {
        path: '/',
        component: () => <Redirect to={`/${primaryExchange.handle}`} push={false} />
    }
];

export default routes;