import gql from 'graphql-tag';
import React, { SFC } from 'react';
import { DataProps, graphql } from 'react-apollo';
import { Img } from 'react-image-loading';
import ObserveSize from 'react-observe-size';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose, lifecycle, withState } from 'recompose';
import { MatchedRoute, serviceRoute } from '../../constants/routes';
import {
    MenuFragment,
    ServiceFragment,
    ServiceMenuFragment,
    ServiceMenuParentFragment,
    ServicesQuery as Response,
} from '../../entities/operationResults';
import { getEntriesOfType, getEntryOfType } from '../../services/entryTypes';
import matchRoute from '../../services/matchRoute';
import styled, { ThemeProps, withTheme } from '../../styled-components';
import DefaultButton from '../atoms/buttons/DefaultButton';
import LinkButton from '../atoms/buttons/LinkButton';
import Container from '../atoms/Container';
import LinkIconRight from '../atoms/icons/LinkIconRight';
import { NavColumn, NavHeading, NavItem, NavLink, NavList } from '../atoms/NavItems';
import { ExchangeParams } from '../Exchange';
import { ServiceParams } from '../Service';
import SectionHeader from '../molecules/SectionHeader';

const GET_SERVICES_QUERY = gql`
    query Services($site: [String!]) {
        services: entry(site: $site, section: "menu", menuDropdownType: "services") {
            children {
                ...ServiceMenuParent
            }
        }
    }

    fragment ServiceMenuParent on menu_menu_Entry {
        children {
            ...ServiceMenu
        }
    }

    fragment ServiceMenu on menu_menu_Entry {
        linkEntry {
            ...Service
        }
    }
    fragment Service on service_service_Entry {
        id
        title
        description
        uri
        slug
        icon {
            url(transform: "serviceTeaser")
            title
        }
    }
`;

const enhance = compose<OutProps, InProps>(
    withRouter,
    graphql(GET_SERVICES_QUERY, {
        options: (props: OutProps) => ({
            variables: {
                site: props.match.params.exchange,
            },
        }),
    }),
    withState('activeIndex', 'setActiveIndex', 0),
    lifecycle<Props, {}>({
        componentDidUpdate(prevProps: Props) {
            if (prevProps.data.loading !== this.props.data.loading && !this.props.data.loading) {
                activeItemUpdate(this.props.data, this.props.setActiveIndex);
            }
        },
        componentDidMount() {
            if (!this.props.data.loading) {
                activeItemUpdate(this.props.data, this.props.setActiveIndex);
            }
        },
    }),
    withTheme
);

interface InProps {
    inMenu?: boolean;
    isDark?: boolean;
    setVisible?: (visible: boolean) => void;
}

interface OutProps extends ThemeProps, RouteComponentProps<ExchangeParams> {
    activeIndex: number;
    setActiveIndex: (index: number) => void;
}

type Props = DataProps<Response> & InProps & OutProps;

function getServices(data: Props['data']) {
    const menuItems: MenuFragment | null = getEntryOfType('menu_menu_Entry', data && data.services);
    const serviceListParent: ServiceMenuParentFragment[] | null = getEntriesOfType(
        'menu_menu_Entry',
        menuItems && menuItems.children
    );

    const serviceList = serviceListParent.flatMap(parent => parent.children as ServiceMenuFragment[]);

    let services: Array<ServiceFragment | null> = [];
    if (serviceList) {
        services = serviceList.map(service =>
            getEntryOfType('service_service_Entry', service && service.linkEntry && service.linkEntry[0])
        );
    }
    return services;
}

function activeItemUpdate(data: Props['data'], setActiveIndex: (index: number) => void) {
    const matchedRoute: MatchedRoute<ServiceParams> | null = matchRoute(location.pathname);

    if (matchedRoute && matchedRoute.match.path === serviceRoute.path) {
        getServices(data).map((service, index) => {
            if (service && service.slug === matchedRoute.match.params.slug) {
                setActiveIndex(index);
            }
        });
    }
}

const Services: SFC<Props> = ({ data, activeIndex, setActiveIndex, theme, inMenu, setVisible, isDark }) => {
    if (data.loading) {
        return null;
    }

    const services = getServices(data);

    const activeService = services[activeIndex];
    if (!activeService) {
        return null;
    }

    const activeIcon = activeService.icon && activeService.icon[0];
    const activeIconURL = activeIcon && activeIcon.url;
    const activeIconAlt = activeIcon && activeIcon.title || undefined;
    const itemClicked = () => {
        if (inMenu && setVisible) {
            setVisible(false);
        }
    };

    return (
        <Container
            paddingTop={inMenu ? '3.2rem' : '4rem'}
            paddingTopL={inMenu ? '3.2rem' : '6.4rem'}
            paddingBottom={inMenu ? '4rem' : '5rem'}
            paddingBottomL={inMenu ? '4rem' : '11rem'}
            maxWidth
        >
            <ObserveSize>
                {({ width }) => (
                    <React.Fragment>
                        <SectionHeader
                            title={
                                <React.Fragment>
                                    <strong>Our Services</strong>
                                </React.Fragment>
                            }
                        />
                        <ServicesContainer>
                            <ServicesNavColumn>
                                <NavList column>
                                    {services.map(
                                        (service, index) =>
                                            service &&
                                            service.uri && (
                                                <NavItem key={service.id || ''}>
                                                    <NavLink
                                                        onMouseEnter={() => setActiveIndex(index)}
                                                        to={`/${service.uri}`}
                                                        data-active={index === activeIndex}
                                                        onClick={itemClicked}
                                                    >
                                                        {service.title}
                                                        <LinkIconRight />
                                                    </NavLink>
                                                </NavItem>
                                            )
                                    )}
                                </NavList>
                            </ServicesNavColumn>
                            {width > theme.mediaQueriesValues.s && activeService && (
                                <InfoContainer>
                                    <Description
                                        orientation={width < theme.mediaQueriesValues.m ? 'vertical' : 'horizontal'}
                                    >
                                        <InformationFlex>
                                            <div>
                                                <NavHeading>What it is</NavHeading>
                                                <Info>{activeService.description}</Info>
                                            </div>
                                            {activeService.uri && (
                                            <Buttons>
                                                <DefaultButton
                                                    colorScheme={isDark ? 'light' : 'dark'}
                                                    to={`/${activeService.uri}`}
                                                    onClick={itemClicked}
                                                >
                                                    <span>LEARN MORE</span>
                                                </DefaultButton>

                                                <LinkButton
                                                    colorScheme={isDark ? 'light' : 'dark'}
                                                    to={`/${activeService.uri}#pricing`}
                                                    onClick={itemClicked}
                                                >
                                                    <span>PRICING</span>
                                                </LinkButton>
                                            </Buttons>
                                        )}
                                        </InformationFlex>
                                        {activeIconURL && (
                                            <ImgWrapper>
                                                <Icon alt={activeIconAlt} src={activeIconURL} />
                                            </ImgWrapper>
                                        )}
                                    </Description>
                                </InfoContainer>
                            )}
                        </ServicesContainer>
                    </React.Fragment>
                )}
            </ObserveSize>
        </Container>
    );
};

const ServicesContainer = styled.div`
    display: inline;

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        display: flex;
    }
`;

const Buttons = styled.div`
    > a {
        margin: 20px;
        margin-top: 10px;
        margin-bottom: 0px;
    }

    > a:first-child {
        margin-left: 0;
    }

    > a:last-child {
        margin-right: 0;
    }
`;

const Icon = styled(Img)`
    max-width: 100%;
`;

const InformationFlex = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
`;

const ImgWrapper = styled.div`
    position: relative;
    margin-left: 5rem;
    display: none;
    align-self: center;
    width: 280px;
    height: 280px;
    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        display: unset;
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.xl}) {
        display: unset;
    }
`;

const InfoContainer = styled.div`
    display: flex;
    flex: 1;
    padding: 0;
`;

const Description = styled.div<{ orientation: 'horizontal' | 'vertical' }>`
    display: flex;
    flex-direction: ${props => (props.orientation === 'horizontal' ? 'row' : 'column')};
`;

const Info = styled.p`
    font-size: 2rem;
    letter-spacing: 0.3px;
    line-height: 3.2rem;
    padding: 0.8rem 0;
    margin: 0;
`;

const ServicesNavColumn = styled(NavColumn)`
    flex: 0 0 10em;

    @media screen and (max-width: ${props => props.theme.mediaQueries.m}) {
        width: 100%;
        margin-right: 0;
        padding-right: 0;
    }
`;

export default enhance(Services);
