import React, { FC, useMemo, useState } from 'react';
import Container from '../../../atoms/Container';
import sites from '../../../../constants/sites';
import { poweredBySites } from '../../../../constants/sites';
import styled from '../../../../styled-components';
import generatePath from 'react-router-dom/generatePath';
import LinkIconRight from '../../../atoms/icons/LinkIconRightV2';
import { exchangeRoute, getStartedRoute } from '../../../../constants/routes';
import { RouteComponentProps, withRouter } from 'react-router';
import { ExchangeParams } from '../../../Exchange';
import { compose } from 'recompose';
import { withTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import { NavHeading, NavItem, NavList } from '../../../atoms/NavItems';
import WorldMap from './WorldMap';
import Flex from '../../../atoms/Flex';
import IconMap from '../../../atoms/icons/IconMap';
import IconList from '../../../atoms/icons/IconList';
import CTABlock from '../../CTABlock';
import ContactSlideover from '../../../organisms/ContactSlideover';


type OutProps = RouteComponentProps<ExchangeParams>;

interface InProps {
    setVisible: (visible: boolean) => void;
}

interface NavColumnProps {
    reverse?: boolean;
}

const enhanced = compose<OutProps, InProps>(
    withTheme,
    withRouter
);

const getCurrentExchangeHandle = () => {
    const handle = window.location.pathname.split('/');
    return handle[1];
};


interface Continents {
    [continent: string]: string[];
  }

const continents: Continents = {
    "Europe": ["ams"],
    "Africa": ["dji", "lag"],
    "Middle East": ["cai", "mnix", "omix"],
    "Asia": ["ban", "hk", "hyd", "kol", "mnl", "mum", "sin", "kuc"],
    'North America': ["bay", "chi"],
    "Caribbean": ["car"],
  }

 function getContinentSites(handles: string[]){
    return sites.filter(site => handles.includes(site.handle)).concat(poweredBySites.filter(site => handles.includes(site.handle)));
}

const DropdownLocations: FC<OutProps & InProps> = ({ setVisible, match }) => {
    const currentExchange = useMemo(() => getCurrentExchangeHandle(), [window.location.pathname]);
    const [isMapView, setIsMapView] = useState(true);
    const [location, setLocation] = useState('');
    const [title, setTitle] = useState('');
    const [isContactSlideoverOpen, setIsContactSlideoverOpen] = useState(false);

    const onHoverLocation = (locationHandle: string, siteTitle: string) => {
        setLocation(locationHandle);
        setTitle(siteTitle);
    };

    const handleUnhover = () => {
        setLocation('');
        setTitle('');
    };
    return (
        <>
            <DropdownLocationsContainer maxWidth={true}>
                <Flex gap={16} alignItems='center' style={{ padding: '64px 0' }}>
                    <LinkIconRight width={38} height={38} color="#F58025" />
                    <ListViewHeading>Locations</ListViewHeading>
                    <MapButton type="button" onClick={() => setIsMapView(!isMapView)}>
                        <span>View {isMapView ? 'list' : 'map'}</span> {isMapView ? <IconList width={24} height={24}/> : <IconMap width={24} height={24}/>}
                    </MapButton>
                </Flex>
                {!isMapView &&
                    <ListViewContainer>
                        {Object.entries(continents).map(([continent, handles]) => {
                            const continentSites = getContinentSites(handles);
                            return (
                                <div className="list-column" key={continent}>
                                    <StyledNavHeading>{continent}</StyledNavHeading>
                                    <ListWrapper>
                                        {continentSites.map((site) => (
                                            <NavItem
                                                key={site.handle}
                                                onMouseOver={() => onHoverLocation(site.handle, site.name)}
                                                onMouseOut={() => handleUnhover()}
                                            >
                                                {!!site.url ? (
                                                    <ExternalNavlink href={site.url} target="_blank">{site.name}</ExternalNavlink>
                                                ) :(
                                                    <NavLink
                                                        isActive={!!currentExchange && currentExchange === site.handle}
                                                        onClick={() => { setVisible(false); } }
                                                        className="location-item"
                                                        to={generatePath(exchangeRoute.path, { exchange: site.handle })}
                                                    >
                                                        {site.name}
                                                    </NavLink>
                                                )}
                                            </NavItem>
                                        ))}
                                    </ListWrapper>
                                </div>
                            );
                        })}
                    </ListViewContainer>
                }
                {isMapView &&
                    <NavWrap>
                        <LocationsNavColumn>
                            {Object.entries(continents).map(([continent, handles]) => {
                                const continentSites = getContinentSites(handles);

                                return (
                                    <div key={continent}>
                                        <StyledNavHeading style={{marginBottom: 16}}>{continent}</StyledNavHeading>
                                    <NavList className={'main-menu'} column>
                                            {continentSites.map((site) => (
                                                <NavItem
                                                    key={site.handle}
                                                    onMouseOver={() => onHoverLocation(site.handle, site.name)}
                                                onMouseOut={() => handleUnhover()}
                                            >
                                                {!!site.url ?
                                                    <ExternalNavlink href={site.url} target="_blank">{site.name}</ExternalNavlink> :
                                                    <NavLink
                                                        isActive={!!currentExchange && currentExchange === site.handle}
                                                        onClick={() => { setVisible(false); }}
                                                        className="location-item"
                                                        to={generatePath(exchangeRoute.path, { exchange: site.handle })}
                                                    >
                                                        {site.name}
                                                    </NavLink>}
                                            </NavItem>
                                            ))}
                                    </NavList>
                                </div>
                                );
                            })}
                        </LocationsNavColumn>
                        <WorldMap locationHandle={location} locationName={title} currentExchange={currentExchange}/>
                    </NavWrap>
                }
            </DropdownLocationsContainer>
            <CTABlock buttonText="Get in touch" buttonLink={`#`} buttonOnClick={() => {
                setIsContactSlideoverOpen(true)
            }}>
                <Flex direction="column" gap={8}>
                    <CTAHeading>Would you like to setup your own IX?</CTAHeading>
                    <CTAText>Get in touch with us and we help you find a hub that is located near you.</CTAText>
                </Flex>
            </CTABlock>
            <ContactSlideover
                isOpen={isContactSlideoverOpen}
                onClose={() => setIsContactSlideoverOpen(false)}
                getStartedPath={generatePath<ExchangeParams>(getStartedRoute.path, { exchange: match.params.exchange })}
            />
        </>
    );
};

const NavWrap = styled.div`
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const DropdownLocationsContainer = styled(Container)`
    height: calc(100vh - 23.5rem);
    overflow-y: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const ListViewContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 3.5rem;
    padding-bottom: 12.5rem;
`

const ListViewHeading = styled.h3`
    color: ${props => props.theme.colors.white};
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 2.4rem;
    margin: 0;
`

const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const StyledNavHeading = styled(NavHeading)`
    font-size: 1.2rem;
    color: ${props => props.theme.colors.baliHai};
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.4rem;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 2.4rem;
`

const MapButton = styled.button`
    background-color: transparent;
    border-radius: 7.1rem;
    border: 1px solid rgba(255, 255, 255, 0.60);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.2rem 1.6rem;
    cursor: pointer;
    margin-left: auto;
    span {
        color: ${props => props.theme.colors.white};
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.1rem;
        letter-spacing: 0.42px;
        text-transform: uppercase;
        margin-right: 1.2rem;
    }

    svg {
        fill: ${props => props.theme.colors.white};
    }

    &:hover {
        background-color: ${props => props.theme.colors.white};
        span {
            color: #192229;
            fill: #192229
        }
        svg {
            color: #192229;
            fill: #192229;
        }
    }
`
interface NavLinkProps {
    isActive: boolean;
}

const NavLink = styled(Link) <NavLinkProps>`
    color: ${(props) => props.theme.colors.black};
    font: 1.6rem/2.4rem chalet-new-york-sixty,sans-serif;
    color: ${(props) => props.isActive ? props.theme.colors.tango : props.theme.colors.white};
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover{
        color: ${(props) => props.theme.colors.tango};
    }
`;

const ExternalNavlink = styled.a`
    color: ${(props) => props.theme.colors.black};
    font: 1.6rem/2.4rem chalet-new-york-sixty,sans-serif;
    color: ${(props) => props.theme.colors.white};
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover{
        color: ${(props) => props.theme.colors.tango};
    }
`;

const LocationsNavColumn = styled.div<NavColumnProps>`
    box-sizing: border-box;
    display: inline-block;
    flex-direction: column;
    height: calc(80vh - 128px);
    justify-content: center;
    overflow-y: scroll;
    ${({ reverse }) => {
        return reverse
            ? `margin-left: auto;`
            : ``;
    }}

    position: relative;
    width: 33.33%;
    padding: 0 2.4rem 124px 0;
    margin-right: 3.2rem;
    scrollbar-width: 12px;
    ::-webkit-scrollbar-thumb {
        width: 6px
    }
    scrollbar-color:  rgba(255, 255, 255, 0.60) #131820;

`;

export const CTAHeading = styled.h2`
    color: ${props => props.theme.colors.darkGray};
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
    letter-spacing: 0.1px;
    margin: 0;
`

export const CTAText = styled.span`
    color: ${props => props.theme.colors.darkGray};
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.4rem; /* 24px */
    letter-spacing: 0.1px;
`

export default enhanced(DropdownLocations);
