import React, { ComponentType, SFC } from 'react';
import styled from '../../styled-components';
import Container from '../atoms/Container';
import DefaultButton from '../atoms/buttons/DefaultButton';
import Seperator from '../atoms/Separator';
import { compose, withState } from 'recompose';
import generatePath from 'react-router-dom/generatePath';
import { ExchangeParams } from '../Exchange';
import { getStartedRoute } from '../../constants/routes';
import { RouteComponentProps, withRouter } from 'react-router';
import { ModalInProps } from '../../hocs/withModal';
import { Block as ButtonBlock } from '../molecules/ButtonContentBlock';
import ReactGA from 'react-ga';

interface InProps {
    ctaText?: string | null;
    slideover: ComponentType<ModalInProps>;
    buttonText?: string | null;
    compact?: boolean | null;
    noSeperator?: boolean;
    id?: string | null;

    // we passthru all other props.
    // todo, this could be cleaner, type info is lost this way (generic type?)
    [key: string]: any;
}

interface OutProps extends RouteComponentProps<ExchangeParams> {
    isModalOpen: boolean;
    setIsModalOpen: (isModalOpen: boolean) => void;
}

const trackOpenFormEvent = () => {
    ReactGA.event({
        category: 'Contactform',
        action: 'Form opened',
        label: location.pathname
    });
};

const Registration: SFC<InProps & OutProps> = ({ setIsModalOpen, isModalOpen, ctaText, buttonText, compact, slideover: Slideover, match, noSeperator, id, ...rest }) => compact ? (
    <Container maxWidth paddingBottom={'6rem'} paddingBottomL={'9.6rem'} marginTopL={'-6.6rem'} className={'pre-footer'} id={id ? `section-${id}` : undefined}>
        <ButtonBlock>
            <DefaultButton
                colorScheme={'colored'}
                onClick={(e) => {
                    e.preventDefault();
                    setIsModalOpen(true);
                    trackOpenFormEvent();
                }}
                to={'#'}
            >
                <span>{buttonText || 'REGISTER'}</span>
            </DefaultButton>
        </ButtonBlock>
        <Slideover
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            getStartedPath={generatePath<ExchangeParams>(getStartedRoute.path, { exchange: match.params.exchange })}
            {...rest}
        />
    </Container>
) : (
    <Container maxWidth={true} paddingTopL={'6.3rem'} paddingBottom={'6rem'} paddingBottomL={'13.2rem'} className={'pre-footer'} id={id ? `section-${id}` : undefined}>
        {!noSeperator && 
            <Seperator />
        }
        <RegistrationWrapper>
            <RegistrationText dangerouslySetInnerHTML={{ __html: ctaText || `<p>Register for this event</p>` }} />
            <DefaultButton
                colorScheme={'colored'}
                onClick={(e) => {
                    e.preventDefault();
                    setIsModalOpen(true);
                    trackOpenFormEvent();
                }}
                to={'#'}
            >
                {buttonText || 'REGISTER'}
            </DefaultButton>
        </RegistrationWrapper>
        {!noSeperator && 
            <Seperator />
        }
        <Slideover
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            getStartedPath={generatePath<ExchangeParams>(getStartedRoute.path, { exchange: match.params.exchange })}
            {...rest}
        />
    </Container>
);

const RegistrationWrapper = styled.div`
    text-align: center;
    padding: 6.4rem 0 8rem 0;
`;

const RegistrationText = styled.div`
    font: ${(props) => props.theme.fonts.small.contactText};
    max-width: 80rem;
    margin: 0 auto 2.4rem;
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.s}) {
        font: ${(props) => props.theme.fonts.medium.contactText};
    }
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.l}) {
        font: ${(props) => props.theme.fonts.large.contactText};
    }
`;

const enhance = compose<OutProps, InProps>(
    withRouter,
    withState('isModalOpen', 'setIsModalOpen', false)
);
export default enhance(Registration);
