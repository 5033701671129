import React, { Component, Fragment } from 'react';
import styled from '../../styled-components';
import IntroTitle from '../atoms/IntroTitle';
import { Form as FormikForm, FormikBag, FormikProps, withFormik } from 'formik';
import * as Form from '../atoms/Form';
import DefaultButton from '../atoms/buttons/DefaultButton';
import Separator from '../atoms/Separator';
import RegisterYourContactDetails from './RegisterYourContactDetails';
import RegisterCompanyDetails from './RegisterCompanyDetails';
import { compose, withState } from 'recompose';
import { boolean, object, BaseSchema as Schema, string } from 'yup';
import theme from '../../constants/theme';
import RegisterBilling from './RegisterBilling';
import RegisterContacts from './RegisterContacts';
import RegisterConfirmation from './RegisterConfirmation';
import PageNav, { MenuItem } from '../organisms/PageNav';
import RegisterCommercialDetails from './RegisterCommercialDetails';
import { RouteComponentProps, withRouter } from 'react-router';
import { ExchangeParams } from '../Exchange';

interface InProps {
    resellerForm?: boolean;
}

interface RepeatableFields {
    sameAs: boolean;
    title: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
    email: string;
    phoneNumber: string;
}

export interface Values {
    title: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
    email: string;
    phoneNumber: string;

    companyName: string;
    url: string;
    industry: string;
    mainLocation: string;

    address: string;
    city: string;
    postalCode: string;
    country: string;
    sameAsRegisteredOfficeAddress: boolean;
    billingName?: string;
    billingAddress?: string;
    billingCity?: string;
    billingPostalCode?: string;
    vatNumber: string;

    BCP: RepeatableFields;
    CCP: RepeatableFields;
    TCP: RepeatableFields;

    NOC: boolean;
    NOCemail: string;
    NOCphoneNumber: string;

    commercialEmail: string;
    typesOfServices: string;

    terms: boolean;
}

interface OutProps extends FormikProps<Values> {
    confirmationPage: boolean;
    setConfirmationPage: (confirmationPage: boolean) => void;
}

type Props = InProps & OutProps & RouteComponentProps<ExchangeParams>;

const menu: MenuItem[] = [{
    id: 'contact-details',
    title: 'Your Contact Details'
}, {
    id: 'company-details',
    title: 'Company Details'
}, {
    id: 'address-and-billing',
    title: 'Address and Billing Information'
}, {
    id: 'contacts',
    title: 'Contacts'
}];

class RegisterForm extends Component<Props> {
    public render() {
        const { submitForm, isSubmitting, isValid, validateForm, values, setConfirmationPage, setTouched, confirmationPage, resellerForm } = this.props;
        const commercialDetailMenuItem = { id: 'commercial-details', title: 'Commercial Details' };
        const index = menu.findIndex(x => x.id === 'commercial-details');

        if (resellerForm && index === -1) {
            menu.push(commercialDetailMenuItem);
        }

        return (
            <Fragment>
                <OuterWrapper>
                    <FormikForm>
                        <Fragment>
                            {confirmationPage ? (
                                <ConfirmationPage>
                                    <IntroTitle>Confirmation</IntroTitle>

                                    <RegisterConfirmation values={values} resellerForm={resellerForm}/>

                                    <Form.Field
                                        name="terms"
                                        label={`By submitting this application form, I accept the <a href="/ams/documentation/general-terms-and-conditions" target="_blank">AMS-IX General Terms and Conditions.</Link>`}
                                        type="checkbox"
                                        defaultChecked={false}
                                    />

                                    <DefaultButton
                                        to="#"
                                        colorScheme={'dark'}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setConfirmationPage(false);
                                        }}
                                    >
                                        GO BACK
                                    </DefaultButton>
                                    <SecondDefaultButton
                                        to="#"
                                        colorScheme={'dark'}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            submitForm();
                                        }}
                                        disabled={isSubmitting}
                                    >
                                        SUBMIT
                                    </SecondDefaultButton>
                                </ConfirmationPage>
                            ) : (
                                <Wrapper>
                                    <NavigationWrapper>
                                        <FormNav menu={menu} isModal={true}/>
                                    </NavigationWrapper>
                                    <div>
                                        <IntroTitle>{resellerForm ? 'Application form reseller' : 'Application form customer'}</IntroTitle>
                                        <ContactText>
                                            <p>Company name and URL will be published on the website. Please note that
                                                you can review the
                                                application form at the final confirmation step, you may also revisit
                                                previous steps by
                                                clicking Previous button.</p>

                                            <p>With regard to the collection and processing of personal data, please see
                                                the <StyledLink href={'/ams/documentation/privacy-policy'} target="_blank">AMS-IX Privacy
                                                    Policy</StyledLink>. By placing this application form you confirm that you are aware
                                                of this policy, and
                                                you provide consent with regard to the purposes stated by AMS-IX to
                                                process this data.</p>
                                        </ContactText>

                                        <StyledSeparator/>
                                        <div id="contact-details">
                                            <FormTitle>Your Contact Details</FormTitle>
                                            <RegisterYourContactDetails/>
                                        </div>

                                        <StyledSeparator/>

                                        <div id="company-details">
                                            <FormTitle id="company-details">Company Details</FormTitle>
                                            <RegisterCompanyDetails resellerForm={resellerForm}/>
                                        </div>

                                        <StyledSeparator/>

                                        <div id="address-and-billing">
                                            <FormTitle id="contacts-and-billing">Address and Billing Information</FormTitle>
                                            <RegisterBilling values={values}/>
                                        </div>

                                        <StyledSeparator/>

                                        <div id="contacts">
                                            <FormTitle>Contacts</FormTitle>
                                            <RegisterContacts values={values}/>
                                        </div>

                                        {resellerForm && (
                                            <Fragment>
                                                <StyledSeparator/>

                                                <div id="commercial-details">
                                                    <FormTitle>Commercial Details</FormTitle>
                                                    <RegisterCommercialDetails />
                                                </div>
                                            </Fragment>
                                        )}

                                        <DefaultButton
                                            to="#"
                                            colorScheme={'dark'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (isValid) {
                                                    setConfirmationPage(true);
                                                    window.scrollTo(0, 0);
                                                } else {
                                                    validateForm().then((validation: any) => setTouched(validation));
                                                }
                                            }}
                                            disabled={!isValid}
                                        >
                                            NEXT
                                        </DefaultButton>
                                    </div>
                                </Wrapper>
                            )}
                        </Fragment>

                    </FormikForm>
                </OuterWrapper>
            </Fragment>
        );
    }
}

const ContactText = styled.div`
    font: ${(props) => props.theme.fonts.small.introText};
    max-width: 80rem;
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.l}) {
        font: ${(props) => props.theme.fonts.large.introText};
    }
`;

const ConfirmationPage = styled.div`
    max-width: 800px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        flex-direction: row-reverse;
        justify-content: space-around;
    }
`;

const FormTitle = styled.h2`
    font: ${(props) => props.theme.fonts.small.registerSubtitle};
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.l}) {
        font: ${(props) => props.theme.fonts.large.registerSubtitle};
    }
`;

const StyledSeparator = styled(Separator)`
    background-color: ${theme.colors.baliHai};
    height: .2rem;
`;

const SecondDefaultButton = styled(DefaultButton)`
    margin-left: 2.2rem;

    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        margin-left: 3.2rem;
    }
`;

const NavigationWrapper = styled.div`
    margin: 3rem 0;
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        width: 320px;
        max-width: 320px;
        margin-top: -40px;
        padding: 0;
    }
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.l}) {
        margin-left: 15rem;
    }
`;

const FormNav = styled(PageNav)`
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        position: fixed;
    }
`;

const StyledLink = styled.a`
  color: ${theme.colors.tango};
  border-bottom: .1rem solid ${theme.colors.tango}
`;

const OuterWrapper = styled.div `
    padding-bottom: 13rem;
`;

const handleSubmit = async (values: Values, { props, setStatus, setSubmitting }: FormikBag<Props, Values>) => {
    const exchange = props.match.params.exchange;
    const retUrl = `${window.location.protocol}//${window.location.host}/${exchange}/thank-you`;

    const fieldValues: {[name: string]: any} = {
        // Salesforce Internal
        'oid': '00D1l0000008cwv',
        'retURL': retUrl,
        'lead_source': props.resellerForm ? 'Website Form Partner' : 'Website Form Customer',
        // 'debug': 1,
        // Your Contact Details
        '00N1l000000e7r1': values.title,
        '00N1l000000e7qy': values.firstName,
        '00N1l000000e7qz': values.lastName,
        '00N1l000000e7r2': values.jobTitle,
        '00N1l000000e7qx': values.email,
        '00N1l000000e7r0': values.phoneNumber,
        // Company Details
        'company': values.companyName,
        'URL': values.url,
        'industry': values.industry,
        '00N1l000000e7rF': values.mainLocation,
        // Contacts and Billing Info
        '00N1l000000e7rJ': values.address,
        '00N1l000000e7rG': values.city,
        '00N1l000000e7rI': values.postalCode,
        '00N1l000000e7rH': values.country,
        '00N1l000000eoNT': values.sameAsRegisteredOfficeAddress ? '1' : '0',
        '00N1l000000e8rN': values.billingAddress,
        '00N1l000000e7rD': values.billingCity,
        '00N1l000000e7rA': values.billingPostalCode,
        '00N1l000000e7rC': values.billingName,
        '00N1l000000e7rB': values.country,
        '00N1l000000e7rY': values.vatNumber,
        // BCP
        '00N1l000000eoQm': values.BCP.sameAs ? '1' : '0',
        '00N1l000000e7r8': values.BCP.title,
        '00N1l000000e7r5': values.BCP.firstName,
        '00N1l000000e7r6': values.BCP.lastName,
        '00N1l000000e7r9': values.BCP.jobTitle,
        '00N1l000000e7r4': values.BCP.email,
        '00N1l000000e7r7': values.BCP.phoneNumber,
        // CCP
        '00N1l000000eoQr': values.CCP.sameAs ? '1' : '0',
        'salutation': values.CCP.title,
        'first_name': values.CCP.firstName,
        'last_name': values.CCP.lastName,
        'title': values.CCP.jobTitle,
        'email': values.CCP.email,
        'phone': values.CCP.phoneNumber,
        // TCP
        '00N1l000000eoQw': values.TCP.sameAs ? '1' : '0',
        '00N1l000000e7rW': values.TCP.title,
        '00N1l000000e7rT': values.TCP.firstName,
        '00N1l000000e7rU': values.TCP.lastName,
        '00N1l000000e7rX': values.TCP.jobTitle,
        '00N1l000000e7rS': values.TCP.email,
        '00N1l000000e7rV': values.TCP.phoneNumber,
        // NOC
        '00N1l000000eoR1': values.NOC ? '1' : '0',
        '00N1l000000e7rK': values.NOCemail,
        '00N1l000000e7rL': values.NOCphoneNumber,
        // Commercial Details
        '00N1l000000f7G0': values.commercialEmail,
        '00N1l000000f7Fg': values.typesOfServices,
    };

    // This is a hack, but we need to submit this like a regular form
    const hiddenForm = document.createElement('form');
    hiddenForm.action = 'https://test.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8';
    hiddenForm.method = 'POST';

    Object.keys(fieldValues).forEach(name => {
        const input = document.createElement('input');
        input.name = name;
        input.value = fieldValues[name];

        hiddenForm.appendChild(input);
    });

    document.body.appendChild(hiddenForm);
    hiddenForm.submit();
    setStatus({ type: 'success' });
    window.scrollTo(0, 0);
    hiddenForm.remove();
    setSubmitting(false);
};

const ISO = /^[\x20-\x7E\xA0-\xA3\xA5\xA7\xA9-\xB3\xB5-\xB7\xB9-\xBB\xBF-\xFF\u20AC\u0160\u0161\u017D\u017E\u0152\u0153\u0178]*$/;
const Alpha = /^\D+$/;
const Url = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
const phoneNumber = /[0-9-+ ()]/;
const repeatableFields = {
    sameAs: true,
    title: '',
    email: '',
    firstName: '',
    lastName: '',
    jobTitle: '',
    phoneNumber: '',
};

const enhance = compose<OutProps, InProps>(
    withRouter,
    withState('confirmationPage', 'setConfirmationPage', false),
    withFormik<Props, Values>({
        validationSchema: (props: Props) => {
            const getContactPersonSchema = (name: string) => object().shape({
                sameAs: boolean(),
                title: string()
                    .when(['sameAs'], {
                        is: false,
                        then: string().required(`Please pick an title for the ${name}`),
                    }),
                firstName: string()
                    .when(['sameAs'], {
                        is: false,
                        then: string().required(`Please enter first name of the ${name}`).matches(Alpha, { message: 'Enter Valid First Name' }),
                    }),
                lastName: string()
                    .when(['sameAs'], {
                        is: false,
                        then: string().required(`Please enter last name of the ${name}`).matches(Alpha, { message: 'Enter Valid Last Name' }),
                    }),
                jobTitle: string(),
                email: string()
                    .when(['sameAs'], {
                        is: false,
                        then: string()
                            .required('Please enter an email address')
                            .email('Please enter a valid email address')
                            .matches(ISO, { message: 'Enter Valid E-mail' }),
                    }),
                phoneNumber: string()
                    .when(['sameAs'], {
                        is: false,
                        then: string()
                            .required(`Please enter the phonenumber of the ${name}`)
                            .matches(phoneNumber, { message: 'Please enter a valid phonenumber' }),
                    }),
            });

            let shape: { [field: string]: Schema<any> } = {
                title: string()
                    .required('Please enter your title'),
                firstName: string()
                    .required('Please enter your first name')
                    .matches(Alpha, { message: 'Enter Valid First Name' }),
                lastName: string()
                    .required('Please enter your last name')
                    .matches(Alpha, { message: 'Enter Valid Last Name' }),
                jobTitle: string(),
                email: string()
                    .required('Please enter an email address')
                    .email('Please enter a valid email address')
                    .matches(ISO, { message: 'Enter Valid E-mail' }),
                phoneNumber: string()
                    .required('Please enter your phonenumber')
                    .matches(phoneNumber, { message: 'Please enter a valid phonenumber' }),
                companyName: string()
                    .required('Please enter your company\'s full name'),
                url: string()
                    .required('Please enter the link to your company\'s website')
                    .matches(Url, { message: 'Please enter a valid link to your company\'s website' }),
                industry: string()
                    .required('Please select an industry from the list'),
                mainLocation: string()
                    .required('Please select an company from the list'),
                address: string()
                    .required('Please enter your address'),
                city: string()
                    .required('Please enter your city'),
                postalCode: string()
                    .required('Please enter your postal code'),
                country: string()
                    .required('Please enter your country'),
                sameAsRegisteredOfficeAddress: boolean(),
                billingName: string()
                    .when(['sameAsRegisteredOfficeAddress'], {
                        is: false,
                        then: string().required('Please fill in your billing name')
                    }),
                billingAddress: string()
                    .when(['sameAsRegisteredOfficeAddress'], {
                        is: false,
                        then: string().required('Please fill in your billing address')
                    }),
                billingCity: string()
                    .when(['sameAsRegisteredOfficeAddress'], {
                        is: false,
                        then: string().required('Please fill in your billing address')
                    }),
                billingPostalCode: string()
                    .when(['sameAsRegisteredOfficeAddress'], {
                        is: false,
                        then: string().required('Please fill in your billing address')
                    }),
                vatNumber: string(),
                BCP: getContactPersonSchema('BCP'),
                CCP: getContactPersonSchema('CCP'),
                TCP: getContactPersonSchema('TCP'),
                NOC: boolean(),
                NOCemail: string()
                    .when(['NOC'], {
                        is: false,
                        then: string()
                            .required('Please enter an email address')
                            .email('Please enter a valid email address')
                            .matches(ISO, { message: 'Enter Valid E-mail' }),
                    }),
                NOCphoneNumber: string()
                    .when(['NOC'], {
                        is: false,
                        then: string()
                            .required('Please enter the phonenumber of the NOC'),
                    }),
            };

            if (props.resellerForm) {
                shape = {
                    ...shape,
                    commercialEmail: string()
                        .required('Please enter an email address')
                        .email('Please enter a valid email address')
                        .matches(ISO, { message: 'Enter Valid E-mail' }),
                    typesOfServices: string()
                        .required('Please enter your types of services'),
                };
            }
            if (props.confirmationPage) {
                shape = {
                    ...shape,
                    terms: boolean()
                        .oneOf([true], 'You must accept the terms to send the form')
                };
            }

            return object().shape(shape);
        },
        handleSubmit,
        mapPropsToValues: () => ({
            title: '',
            email: '',
            firstName: '',
            lastName: '',
            jobTitle: '',
            phoneNumber: '',
            companyName: '',
            url: '',
            industry: '',
            mainLocation: '',
            address: '',
            city: '',
            postalCode: '',
            country: '',
            sameAsRegisteredOfficeAddress: true,
            billingName: '',
            billingAddress: '',
            billingCity: '',
            billingPostalCode: '',
            vatNumber: '',
            BCP: repeatableFields,
            CCP: repeatableFields,
            TCP: repeatableFields,
            NOC: true,
            NOCemail: '',
            NOCphoneNumber: '',
            commercialEmail: '',
            typesOfServices: '',
            terms: false,
        }),
    })
);

export default enhance(RegisterForm);
