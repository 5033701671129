import gql from 'graphql-tag';
import React, { SFC } from 'react';
import styled from '../../styled-components';
import Container from '../atoms/Container';
import { UsersContentBlockFragment } from '../../entities/operationResults';
import IntroTitle from '../atoms/IntroTitle';
import { pathOr } from 'ramda';
import placeholderAvatar from '../../assets/ams-ix-avatar.svg';

export const fragment = gql`
    fragment UsersContentBlock on contentBlocks_users_BlockType {
        id
        heading
        users {
            ... on User {
                fullName
                functionTitle
                photo {
                    url(transform: "newsThumbnail")
                }
            }
        }
    }
`;

interface Props {
    contentBlock: UsersContentBlockFragment;
}

const UsersContentBlock: SFC<Props> = ({ contentBlock: { id, heading, users } }) => {
    return (
        <Container maxWidth paddingBottom={'6rem'} paddingBottomL={'12rem'}>
            <Wrapper id={id ? `section-${id}` : undefined}>
                {heading && (
                    <IntroTitle>{heading}</IntroTitle>
                )}
                <UserList>
                {users && users.map((user, index) => {
                    const photoUrl = pathOr(placeholderAvatar, ['photo', 'url'], user || {});
                    return (
                    <User key={index}>
                        <UserImage src={photoUrl} alt={user && user.fullName || undefined}/>
                        <UserInfo>
                            <UserDescription>{user && user.functionTitle}</UserDescription>
                            <UserTitle>{user && user.fullName}</UserTitle>
                        </UserInfo>
                    </User>
                    );
                })}
                </UserList>
            </Wrapper>
        </Container>
    );
};

const Wrapper = styled.div`
    position: relative;
    scroll-margin-top: 88px;
    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        max-width: 900px;
        margin: 0 auto;
        
        > div, > img {
            max-width: 75%;
        }
    }
`;

const UserList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

const User = styled.li`
    display: flex;
    align-items: center;
    border-bottom: solid 0.1rem ${(props) => props.theme.colors.mystic};
    padding: 2.4rem 0;
    &:last-child {
      border-bottom: none;
    }
`;
const UserInfo = styled.div`
    margin-left: 3.2rem;
`;

const UserDescription = styled.span`
    font: ${props => props.theme.fonts.large.eventDate};
    color: ${props => props.theme.colors.tango};
    margin: 0;
    text-transform: uppercase;
`;

const UserTitle = styled.h2`
    margin: 0;
    font: ${props => props.theme.fonts.small.sectionItemTitle};
    transition: .2s color ease-in-out;

    @media screen and (min-width: ${(props) => props.theme.mediaQueries.s}) {
        font: ${props => props.theme.fonts.medium.sectionItemTitle};
    }
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        font: ${props => props.theme.fonts.large.sectionItemTitle};
    }
`;

const UserImage = styled.img`
  border-radius: 50%;
  width: 14rem;
  height: 14rem;
  object-fit: cover;
  object-position: center;
`;

export default UsersContentBlock;