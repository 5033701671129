import gql from 'graphql-tag';
import { path } from 'ramda';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { DataProps, graphql } from 'react-apollo';
import { Img } from 'react-image-loading';
import { RouteComponentProps, withRouter } from 'react-router';
import generatePath from 'react-router-dom/generatePath';
import Scroll from 'react-scroll';
import { branch, compose, lifecycle, renderComponent, withState } from 'recompose';

import { getStartedRoute, serviceRoute, serviceSlideoverRoute } from '../constants/routes';
import {
  ServicePageFragment,
  ServiceQuery,
  ServiceQueryVariables,
  ShowcaseStoryFragment,
} from '../entities/operationResults';
import withSpinner from '../hocs/withSpinner';
import { getEntryOfType, getRelatedItems } from '../services/entryTypes';
import styled, { ThemeProps, withTheme } from '../styled-components';
import DefaultButton from './atoms/buttons/DefaultButton';
import Container from './atoms/Container';
import Meta from './atoms/Meta';
import Separator from './atoms/Separator';
import { ExchangeParams } from './Exchange';
import * as Intro from './molecules/Intro';
import ServiceBenefits, { serviceBenefitsFragment } from './molecules/ServiceBenefits';
import StoryShowcase, { storyShowcaseFragment } from './molecules/StoryShowcase';
import NotFound from './NotFound';
import ContactSlideover from './organisms/ContactSlideover';
import ContactUs, { trackOpenContactFormEvent } from './organisms/ContactUs';
import PageNav, { MenuItem } from './organisms/PageNav';
import ServiceSlideover from './organisms/ServiceSlideover';
import RelatedDetail, { relatedFragments } from './organisms/RelatedDetail';
import Flex from './atoms/Flex';
import PricingTable, {CommitmentModuleSpeedBlock} from "./Pricing";
import getCanonicalUrl from '../constants/getCanonicalUrl';

export const SERVICE_NAV_WIDTH = 125;
const GET_SERVICE_QUERY = gql`
    query Service($exchange: [String!], $slug: [String!]) {
        service: entry(section: "service", site: $exchange, slug: $slug) {
            ...ServicePage
        }
    }

    fragment ServicePage on service_service_Entry {
        id
        title
        howItWorks
        description
        pricing
        showDurationSwitch
        showContactPricing
        commitmentModule {
            ... on commitmentModule_speedBlock_BlockType {
                blockTitle
                price3Months
                price12Months
                price24Months
                price36Months
                checkmarkList {
                    listItem
                }
            }
        }
        icon {
            src: url(transform: "serviceLogo")
            width: width(transform: "serviceLogo")
            src2x: url(transform: "serviceLogo2x")
            width2x: width(transform: "serviceLogo2x")
            title
        }
        benefits {
            ...ServicePageBenefit
        }
        storyShowcase {
            ...ShowcaseStory
        }
        contactBlockCtaText
        contactFormText
        relatedItems {
            ...Related
        }
    }

    ${relatedFragments}
    ${serviceBenefitsFragment}
    ${storyShowcaseFragment}
`;

export interface ServiceParams extends ExchangeParams {
    slug: string;
}

type Props = DataProps<ServiceQuery, ServiceQueryVariables> &
    RouteComponentProps<ServiceParams> &
    ThemeProps & {
        isModalOpen: boolean;
        setIsModalOpen: (isModalOpen: boolean) => void;
    };

const menu: MenuItem[] = [
    {
        id: 'intro',
        title: 'Introduction',
    },
    {
        id: 'benefits',
        title: 'Benefits',
    },
    {
        id: 'pricing',
        title: 'Pricing',
    },
];

const Service: FC<Props> = ({ data, theme, isModalOpen, setIsModalOpen, match, history, location }) => {
    const service: ServicePageFragment | null = getEntryOfType('service_service_Entry', data.service);
    const slideoverPath = generatePath<ServiceParams>(serviceSlideoverRoute.path, { exchange : match.params.exchange,  slug: match.params.slug });
    const servicePath = generatePath<ServiceParams>(serviceRoute.path, { exchange: match.params.exchange,  slug: match.params.slug })
    const [isContactSlideoverOpen, setIsContactSlideoverOpen] = useState(false);
    if (!service) {
        return null;
    }

    const setModal = (value: boolean) => {
        setIsModalOpen(value);
        if (value) {
            history.push(slideoverPath)

        } else {
            history.push(servicePath)
        }
    }

    useEffect(()=>{
        if(location.pathname === slideoverPath){
            setModal(true)
        }
    }, []) 

    const showcaseStory: ShowcaseStoryFragment | null = getEntryOfType('story_story_Entry', service.storyShowcase && service.storyShowcase[0]);
    const alt = path<string>(['icon', 0, 'title'], service);
    const icon = path<string>(['icon', 0, 'src'], service);
    const icon2x = path<string>(['icon', 0, 'src2x'], service);
    const pricing = path<string>(['pricing'], service);
    const showDurationSwitch = path<boolean>(['showDurationSwitch'], service);
    const showContactButton = path<boolean>(['showContactPricing'], service);
    const commitmentModule = path<CommitmentModuleSpeedBlock[]>(['commitmentModule'], service);
    const relatedItems = getRelatedItems(service.relatedItems);
    return (
        <Fragment>
            <Meta title={service.title || ''} description={service.description} image={icon} canonical={getCanonicalUrl(match)}/>

            <ServiceContainerDark maxWidth>
                <ServiceHero>
                    <ServiceNav menu={menu} />

                    <ServiceContent>
                        <Scroll.Element name="intro">
                            <ServiceSection>
                                {icon && (
                                    <ServiceIconWrapper>
                                        <ServiceIconMobile alt={alt} src={icon} srcSet={`${icon2x} 2x, ${icon} 1x`} />
                                    </ServiceIconWrapper>
                                )}
                                <ServiceTitle>{service.title}</ServiceTitle>
                                <ServiceSeparator />
                                {service.description && <Intro.Text>{service.description}</Intro.Text>}
                                <Flex style={{marginTop: 40}} gap={16}>
                                    <DefaultButton colorScheme="colored" to={'#'} onClick={() => setModal(true)}>
                                        Learn more
                                    </DefaultButton>
                                    <DefaultButton colorScheme="dark" to={'#'} href="mailto:sales@ams-ix.net" onClick={() => setIsContactSlideoverOpen(true)} style={{border: '1px solid rgba(255, 255, 255, 0.60)'}}>
                                        Contact us
                                    </DefaultButton>
                                </Flex>
                            </ServiceSection>
                        </Scroll.Element>
                    </ServiceContent>
                    {icon && (
                        <ServiceIconWrapper>
                            <ServiceIcon alt={alt} src={icon} srcSet={`${icon2x} 2x, ${icon} 1x`} />
                        </ServiceIconWrapper>
                    )}
                </ServiceHero>
            </ServiceContainerDark>
            <BenefitsContainerDark maxWidth>
                {service.benefits && <ServiceBenefits benefits={service.benefits} serviceTitle={service.title || ''} />}
            </BenefitsContainerDark>
            {showcaseStory && <StoryShowcase story={showcaseStory} />}
            <ServiceContainer
                id="pricing"
                maxWidth
                paddingBottom="0rem"
                paddingBottomL="0rem"
                paddingTopL="4rem"
                paddingTop="2rem"
            >
                <LowerServiceContent>
                    {commitmentModule && commitmentModule.length > 0 && <ServiceContainer
                        id="commitment"
                    >
                        <LowerServiceContent>
                            <Scroll.Element name="commitmentModule">
                                <PricingTable showDurationSwitch={!!showDurationSwitch} data={commitmentModule} />
                            </Scroll.Element>
                        </LowerServiceContent>
                    </ServiceContainer>}

                    {showContactButton && (
                        <ContactUs
                            compact={false}
                            slideover={ContactSlideover}
                            contactFormText={path<string>(['contactFormText', 'content'], service)}
                            ctaText={path<string>(['contactBlockCtaText', 'content'], service)}
                            service={true}
                            ctaButtons={[
                                <Flex dir="flex-row" key="buttons">
                                    <div >
                                        <DefaultButton
                                            colorScheme={'light'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setIsModalOpen(true);
                                                trackOpenContactFormEvent();
                                            }}
                                            to={'#'}
                                            style={{border:  '1px solid rgba(23, 34, 42, 0.20)', marginRight: 16}}
                                        >
                                            {!!pricing ? 'See all prices' : 'Request quote'}
                                        </DefaultButton>
                                    </div>
                                    <div >
                                        <DefaultButton
                                            colorScheme={'colored'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setIsContactSlideoverOpen(true);
                                                trackOpenContactFormEvent();
                                            }}
                                            to={'#'}
                                        >
                                            Contact Us
                                        </DefaultButton>
                                    </div>
                                </Flex>
                            ]}
                        />
                    )}
                    {!showContactButton && (
                        <ContactUs
                            compact={false}
                            service={true}
                            slideover={ContactSlideover}
                            contactFormText={path<string>(['contactFormText'], service)}
                            ctaText={path<string>(['contactBlockCtaText'], service)}
                        />
                    )}
                </LowerServiceContent>
            </ServiceContainer>
            {relatedItems && relatedItems.length > 0 && <RelatedDetail relatedItems={relatedItems} />}
            <ServiceSlideover
                isOpen={isModalOpen}
                onClose={() => setModal(false)}
                getStartedPath={generatePath<ExchangeParams>(getStartedRoute.path, {
                    exchange: match.params.exchange,
                })}
            />
            <ContactSlideover
                isOpen={isContactSlideoverOpen}
                onClose={() => setIsContactSlideoverOpen(false)}
                getStartedPath={generatePath<ExchangeParams>(getStartedRoute.path, { exchange: match.params.exchange })}
            />
        </Fragment>
    );
};

const enhance = compose(
    withTheme,
    withRouter,
    graphql(GET_SERVICE_QUERY, {
        options: ({ match }: Props) => ({
            variables: match.params,
        }),
    }),
    lifecycle<Props, {}, { jump: (this: { props: Props }) => void }>({
        jump() {
            if (typeof window === 'undefined') {
                return;
            }

            // we're using the global location object here because pushing changes to
            // the history don't trigger the jump for whatever reason
            const { hash } = this.props.location;
            setTimeout(() => {
                // need to clear hash in order to trigger jump...
                window.location.hash = '';
                window.location.hash = hash;
            });
        },

        componentDidMount() {
            if (this.props.location.hash && typeof window !== 'undefined') {
                this.jump();
            }
        },

        componentDidUpdate(prev: Props) {
            const service: ServicePageFragment | null = getEntryOfType('service_service_Entry', this.props.data.service);
            const nextService: ServicePageFragment | null = getEntryOfType('service_service_Entry', prev.data.service);
            const shouldTriggerJump =
                (service && nextService && service.id !== nextService.id) ||
                (!this.props.data.loading && prev.data.loading !== this.props.data.loading);
            if (shouldTriggerJump && this.props.location.hash) {
                this.jump();
            }
        },
    }),
    withSpinner,
    branch(({ data }: Props) => !data.service, renderComponent(NotFound)),
    withState('isModalOpen', 'setIsModalOpen', false),
);

export default enhance(Service);

const ServiceNav = styled(PageNav)`
    min-width: 11rem;
    width: 100%;
    padding: 0;
    margin-bottom: 1.6rem;

    @media screen and (min-width: ${props => props.theme.mediaQueries.s}) {
        margin-bottom: 4.8rem;
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        position: fixed;
        right: 5rem;
        top: 10rem;
        align-self: flex-start;
        z-index: 5;
        width: ${SERVICE_NAV_WIDTH}px;
    }
`;

const ServiceContent = styled.div`
    flex: 1;
`;

const LowerServiceContent = styled.div`
    width: 100%;
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        flex: 1;
    }
`;

const ServiceHero = styled.div`
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        display: flex;
    }
`;

const ServiceContainer = styled(Container)`
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) and (max-width: ${props =>
            props.theme.mediaQueriesValues.xxl + SERVICE_NAV_WIDTH}px) {
        > div {
            padding-right: 12rem;
        }
    }
    background:${props => props.theme.colors.aquaHaze};
    padding-bottom: 0 !important;
`;

const ServiceContainerDark = styled(ServiceContainer)`
    background: ${props => props.theme.colors.mirage};
    color: ${props => props.theme.colors.white};
    padding-top: 0.8rem;

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        padding-top: calc(50vh - 20rem - 7.2rem);
    }
`;

const BenefitsContainerDark = styled(ServiceContainer)`
    padding-top: 6.4rem;
    padding-bottom: 1.6rem;
    background: ${({ theme }) => theme.colors.vulcan};

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        padding-top: 12rem;
        padding-bottom: 5.6rem;
    }
`;

const ServiceTitle = styled.h1`
    font: 4.2rem/4.4rem chalet-new-york-sixty, sans-serif;

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        font-size: 6rem;
        line-height: 6.4rem;
    }
`;

const ServiceIconWrapper = styled.div`
    margin: 0 auto;
    display: none;
    position: relative;

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        position: sticky;
        align-self: flex-start;
        top: calc(50vh - 16rem);
        margin-left: 4rem;
        display: inherit;
        width: 32rem;
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        top: calc(50vh - 20rem);
        width: 40rem;
        margin-left: 8rem;
        margin-bottom: 10.4rem;
    }
`;

const ServiceIcon = styled(Img)`
    width: 100%;
`;

const ServiceIconMobile = styled(ServiceIcon)`
    display: block;
    width: 28rem;
    margin-bottom: 2rem;

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        display: none;
    }
`;

const ServiceSection = styled.div`
    padding-bottom: 6.4rem;
    max-width: 70rem;

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        padding-bottom: 13.2rem;
    }
`;

export const ServiceSeparator = styled(Separator).attrs(props => ({
    color: props.theme.colors.tango,
}))`
    height: 3px;
    width: 10%;
`;
