import React, { ReactNode } from 'react';
import styled from '../../styled-components';
import { Link } from 'react-router-dom';
import LazyImg from '../atoms/LazyImg';

interface Props {
    link: string;
    img?: {
        src: string | null;
        width: string | null;
        src2x: string | null;
        width2x: string | null;
        title: string | null;
    };
    date: string | null;
    children: ReactNode;
}

const NewsPreview = ({ children, img, link }: Props) => (
    <NewsWrap to={link} className={'news-item'}>
        <ImgWrapper>
            <NewsThumb
                alt={img && img.title || undefined}
                src={img && img.src || undefined}
                srcSet={`
                    ${img && img.src} ${(img && img.width)}w,
                    ${img && img.src2x} ${(img && img.width2x)}w`}
                sizes={'(min-width: 990px) 10vw, 140px'}
            />
        </ImgWrapper>
        <Excerpt>{children}</Excerpt>
    </NewsWrap>
);

const Excerpt = styled.p`
    margin: 0;
    font: ${(props) => props.theme.fonts.small.sectionItemTitle};
    color: ${(props) => props.theme.colors.black};
    transition: color 0.2s;
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        font: ${(props) => props.theme.fonts.large.sectionItemTitle};
    }
`;

const NewsWrap = styled(Link)`
    flex: 1 100%;
    text-decoration: none;
    text-align: left;
    border-bottom: 1px solid ${(props) => props.theme.colors.alto};
    padding: 0 0 2.8rem 0;
    margin-bottom: 3.2rem;
    
    &:last-child{
        border-bottom: none;
    }
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.s}) {
        flex: 1 calc(50% - 4.3rem);
        border-bottom: none;
        padding: 3.2rem 4.3rem;
        margin-bottom: 0;
    }
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.s}) and (max-width: ${(props) => props.theme.mediaQueries.l}) {
        &:nth-child(odd){
            padding-left: 0;
            padding-right: 4.2rem;
            border-right: 1px solid ${(props) => props.theme.colors.alto};
        }
        &:nth-child(even){
            padding-right: 0;
        }
    }
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.l}) {
        flex: 1 calc(25% - 4.3rem);
        padding: 3.2rem 2.7rem;
        border-right: 1px solid ${(props) => props.theme.colors.alto};
        &:first-child{
            padding-left: 0;
        }
        &:last-child{
            padding-right: 0;
            border-right: none;
        }
    }
    
    &:hover ${Excerpt} {
        color: ${props => props.theme.colors.tango};
    }
`;

const NewsThumb = styled(LazyImg)`
    margin-bottom: 19px;
    height: 14rem;
    width: 14rem;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
`;

const ImgWrapper = styled.div`
    position: relative;
`;

export default NewsPreview;
