import gql from 'graphql-tag';
import React, { SFC } from 'react';
import { RegistrationFormContentBlockFragment } from '../../entities/operationResults';
import Registration from '../organisms/Registration';
import ContactSlideover from '../organisms/ContactSlideover';

export const fragment = gql`
    fragment RegistrationFormContentBlock on contentBlocks_registrationForm_BlockType {
        id
        ctaText
        buttonText
        compactDisplay
        description
    }
`;

interface Props {
    contentBlock: RegistrationFormContentBlockFragment;
}

const RegistrationFormContentBlock: SFC<Props> = ({ contentBlock: { id, ctaText, description, buttonText, compactDisplay } }) => {
    const blockId = parseInt(id || '', 10);
    
    return (
        <Registration
            slideover={ContactSlideover}
            contactBlockId={blockId}
            ctaText={ctaText}
            contactFormText={description}
            buttonText={buttonText}
            compact={compactDisplay}
            documentUpload={false}
            isRegistrationForm={true}
            id={id ? `section-${id}` : undefined}
        />
    );
};

export default RegistrationFormContentBlock;