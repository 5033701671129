import { RouteComponentProps } from 'react-router';
import React, { Fragment, SFC } from 'react';
import {DataProps, graphql, useQuery} from 'react-apollo';
import { branch, compose, renderComponent } from 'recompose';
import NotFound from './NotFound';
import Meta from './atoms/Meta';
import withSpinner from '../hocs/withSpinner';
import {GLOSSARY_QUERY} from './molecules/TextContentBlock';
import gql from "graphql-tag";
import {
    GlossaryQuery as Response
} from '../entities/operationResults';
import styled, { withTheme } from 'styled-components';
import Container from './atoms/Container';
import ContentBody from './atoms/ContentBody';
import ContentTitle from './atoms/ContentTitle';

const GET_GLOSSARY_QUERY = gql`
    query Glossary ($exchange: [String!]) {
        globalSets(site: $exchange, handle: "glossary") {
            name
            ...Glossary
        }
    }

    fragment Glossary on glossary_GlobalSet {
        glossaryTable {
            word
            meaning
        }
    }

`;
export interface GlossaryParams {
    exchange?: string[] | null;
}

type Props = RouteComponentProps<GlossaryParams> & DataProps<Response>;

const Glossary: SFC<Props> = ({ data: { globalSets } }) => {
    const { data: glossaryData } = useQuery(GLOSSARY_QUERY);
    const wordMeanings = glossaryData ? glossaryData.glossary.glossaryTable : [];
    return (
        <Fragment>
            <Meta title={glossaryData && glossaryData.name || 'Glossary'} />
            <Container maxWidth paddingBottom={'6rem'} paddingBottomL={'9.6rem'} paddingTop={'4rem'} paddingTopL={'8.8rem'}>
                <ContentTitle>Glossary</ContentTitle>
                <ContentBody>
                    <Table className="glossary">
                        <thead>
                        <tr>
                            <th>Term</th>
                            <th>Definition</th>
                        </tr>
                        </thead>
                        <tbody>
                        {wordMeanings.map((object: any) => (
                            <tr>
                                <td className="term">{object.word}</td>
                                <td className="definition">{object.meaning}</td>
                            </tr>
                            )
                        )}
                        </tbody>
                    </Table>
                </ContentBody>
            </Container>
        </Fragment>
    );
};

const enhance = compose(
    withTheme,
    graphql(GET_GLOSSARY_QUERY, {
        options: ({ match: { params: { exchange } } }: Props) => ({
            variables: {
                exchange
            }
        })
    }),
    withSpinner,
    branch(
        ({ data }: Props) => !data.globalSets,
        renderComponent(NotFound)
    )
);


export default enhance(Glossary);

const Table = styled.table`
    &.glossary {
        overflow-y: auto;
        min-width: 100%;

        tr th:last-child {
            text-align: right;
        }

    }
`;