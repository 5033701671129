
import React from 'react';
import styled from '../../../styled-components';
import { FooterLinkFragment } from '../../../entities/operationResults';
import { Link } from 'react-router-dom';
import { startOfToday, format } from 'date-fns';

interface Props {
    footerLinks: FooterLinkFragment[];
    siteHandle: string;
}

const FooterLinks = ({ footerLinks, siteHandle }: Props) => {
    return (
        <FooterLinksWrap>
            <p>© {format(startOfToday(), 'YYYY')} - Amsterdam Internet Exchange&nbsp;
                {footerLinks.map((footerLink) => {
                    const link = footerLink.linkEntry && footerLink.linkEntry[0];
                    return (
                        link && link.uri &&
                            <NavLink key={footerLink.id || ''} to={`/${link.uri}`}> {footerLink.title}&nbsp;</NavLink>
                    );
                })}
                <NavLink to={`/${siteHandle}/glossary`}>Glossary Page</NavLink>
                <br/>
                Trade register: 34128666
            </p>
        </FooterLinksWrap>
    );
};

const FooterLinksWrap = styled.div`
  color: ${(props) => props.theme.colors.baliHai};
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: bold;
  flex: 2;
  margin-bottom: 2rem;
  p{
    margin:0;
  }
  @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
    margin-right: 8rem;
    margin-bottom: 0;
  }
  @media screen and (min-width: ${(props) => props.theme.mediaQueries.l}) {
    margin-right: 24.9rem;
  }
`;
const NavLink = styled(Link)`
  color: white;
  font-weight: bold;
  display: block;
  font-size: 1.4rem;
  line-height: 2.4rem;
  display: inline-block;
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: bold;
  &:before{
    content: '//';
    color: ${(props) => props.theme.colors.baliHai};
  }
`;

export default FooterLinks;
