import React, { SFC } from 'react';
import ObserveSize from 'react-observe-size';
import { RouteComponentProps, withRouter } from 'react-router';
import { LinkProps } from 'react-router-dom';
import { compose } from 'recompose';
import sites from '../../../constants/sites';
import { DropdownTyping } from '../../../entities/DropdownType';
import { MenuScheme } from '../../../entities/operationResults';
import styled from '../../../styled-components';
import ArrowDownIcon from '../../atoms/icons/ArrowDownIcon';
import { ExchangeParams } from '../../Exchange';

type OutProps = RouteComponentProps<ExchangeParams> & LinkProps;

interface InProps {
    menuScheme: MenuScheme;
    setVisible: (visible: boolean) => void;
    setExchangeVisible: (visible: boolean) => void;
    exchangeVisible: boolean;
    setActiveIndex: (activeDropdownIndex: number | null) => void;
    setDropdownType: (dropdownType: DropdownTyping) => void;
}

const enhanced = compose<OutProps, InProps>(withRouter);

const HeaderExchangePicker: SFC<InProps & OutProps> = ({
    menuScheme,
    match,
    setDropdownType,
    setVisible,
    setActiveIndex,
    setExchangeVisible,
    exchangeVisible,
}) => {
    const exchange = sites.find(site => site.handle === match.params.exchange);
    return (
        <ObserveSize>
            {({ width }) => (
                <HeaderExchangePickerWrap
                    onMouseOver={() => {
                        setDropdownType('locations');
                        setVisible(true);
                        setActiveIndex(null);
                    }}
                    onClick={() => {
                        setExchangeVisible(!exchangeVisible);
                    }}
                    scheme={menuScheme}
                    className={'location-picker'}
                >
                    <PickerText scheme={menuScheme}>
                        <span>LOCATION</span>
                        <br />
                        {exchange !== undefined && exchange.name}
                    </PickerText>
                    {width >= 200 && <ArrowDownIcon type={'small'} />}
                </HeaderExchangePickerWrap>
            )}
        </ObserveSize>
    );
};

const HeaderExchangePickerWrap = styled.div<{ scheme: MenuScheme }>`
    border-left: 1px solid ${({ theme }) => theme.colors.shuttleGray};
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 0.4rem 0.8rem;
    margin: 0 0 0 0.8rem;
    cursor: pointer;
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        padding: 0 1.8rem;
        margin: 0 0 0 1.2rem;
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        padding: 0 1.8rem;
        flex-wrap: nowrap;
        flex-direction: unset;
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.xl}) {
        padding: 0 2.4rem;
    }
`;

const PickerText = styled.p<{ scheme: MenuScheme }>`
    margin: 0;
    font-size: 1rem;
    line-height: 0.6rem;
    font-family: chalet-new-york-sixty;
    color: inherit;
    span {
        font-family: 'urw-din';
        color: ${({ theme }) => theme.colors.white};
        font-size: 1rem;
        line-height: 1.2rem;
        letter-spacing: 0.04rem;
        display: none;
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        font-size: 1.2rem;
        line-height: 1.6rem;
        margin: 0 4.2rem 0 1.6rem;

        span {
            display: unset;
        }
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.xl}) {
        line-height: 2rem;
        font-size: 1.6rem;
    }
`;

export default enhanced(HeaderExchangePicker);
